import React from 'react'
import get from 'lodash/get'

import * as messages from '../../messages.json'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { SEOMetaTags } from 'blog-components'

class Deals extends React.Component {
  successComponent() {
    return (
      <div className="main-container">
        <div className="row justify-content-center no-gutters text-center">
          <div className="col-sm-8">
            <h1 className="display-4 py-5">
              {messages.pages.latestdeals.components.success.heading}
            </h1>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const site = get(this, 'props.data.site')

    return (
      <Layout>
        <div className="container fadein-3 page" data-emergence="visible">
          <SEOMetaTags
            path="/latest-deals/"
            site={site}
            title={messages.pages.latestdeals.metaData.title}
            description={messages.pages.latestdeals.metaData.description}
            imageUrl={site.siteMetadata.siteUrl + '/main-logo.png'}
          />
          <div className="row justify-content-center">
            <div className="col-12">
              <h1 className="display-4 text-center py-5 ">
                {messages.pages.latestdeals.components.heading}
              </h1>
              <p className="lead">
                {messages.pages.latestdeals.components.paragraph_1}
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <p className="display-6 text-center">
                {messages.pages.latestdeals.components.nodeals}
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Deals
export const query = graphql`
  query dealQuery {
    site {
      ...SiteInformation
    }
  }
`
